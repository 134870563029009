import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { useAuth0 } from '@auth0/auth0-react';
import { reconciliate, downloadS3, uploadS3 } from '../../utils/api';
import { reconciliatorS3KeyFolder } from '../../constants';
import MD5 from 'crypto-js/md5';
import Error from './error';
import Success from './success';
import PhaseSelector from './PhaseSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { DocumentIcon } from '@heroicons/react/24/outline'
import toast from 'react-hot-toast';

const FileDropzone: React.FC = () => {
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [auth0Token, setAuth0Token] = useState('');
  const [selectedPhase, setSelectedPhase] = useState('phase1');
  const { getAccessTokenSilently } = useAuth0();

  const [processStatus, setProcessStatus] = useState('');
  const [processError, setProcessError] = useState('');
  const [filesSelected, setFilesSelected] = useState(false);

  useEffect(() => {
    if (processStatus === 'Error') {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [processStatus]);

  useEffect(() => {
    // Clear files when phase changes
    setFiles([]);
    setFilesSelected(false);
    setProcessStatus('');
    setProcessError('');
  }, [selectedPhase]);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setFiles([]);
        setFilesSelected(false);
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const getRequiredFileCount = (phase: string) => {
    switch (phase) {
      case 'phase1':
        return 1;
      case 'phase2':
        return 3;
      case 'phase3':
        return 1;
      default:
        return 1;
    }
  };

  const hasCorrectFileCount = () => {
    const required = getRequiredFileCount(selectedPhase);
    return files.length === required;
  };

  const getPhaseFileMessage = () => {
    const required = getRequiredFileCount(selectedPhase);

    const getPhaseRequirements = () => {
      switch (selectedPhase) {
        case 'phase1':
          return (
            <>
              <div className="text-sm text-gray-600 mt-1">
                <div className="flex-1">
                  <p className="pb-1 mb-3 border-b-2 border-gray-200"></p>
                  <div className="border-l-2 border-sky-100 pl-4">
                    <ul className="space-y-1.5">
                      <li className="flex items-start text-left">
                        <span className="w-1 h-1 bg-sky-600 rounded-full mr-2 mt-2"></span>
                        <div>
                          Reconciliation table filled correctly in <span className="font-medium text-emerald-600">Reconciliation Sheet</span>
                          <ul className="space-y-1.5 mt-1.5 ml-4">
                            <li className="flex items-center text-left">
                              <span className="w-1 h-1 bg-sky-600/60 rounded-full mr-2 mt-0.5"></span>
                              Employee number in <span className="font-medium text-amber-600">&nbsp;column A</span>&nbsp;
                            </li>
                            <li className="flex items-center text-left">
                              <span className="w-1 h-1 bg-sky-600/60 rounded-full mr-2 mt-0.5"></span>
                              Table data starting from <span className="font-medium text-amber-600">&nbsp;row 17</span>&nbsp;
                            </li>
                            <li className="flex items-center text-left">
                              <span className="w-1 h-1 bg-sky-600/60 rounded-full mr-2 mt-0.5"></span>
                              Variance threshold in <span className="font-medium text-amber-600">&nbsp;B13</span>&nbsp;<span className="text-gray-500 italic">(Optional)</span>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li className="flex items-center text-left">
                        <span className="w-1 h-1 bg-sky-600 rounded-full mr-2 mt-0.5"></span>
                        <span className="font-medium text-sky-700">Payroll Value Source</span>&nbsp;in<span className="font-medium text-emerald-600">&nbsp;Mapping Sheet</span>,&nbsp;<span className="font-medium text-amber-600">Column G</span>&nbsp;
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          );
        case 'phase2':
          return (
            <>
              <div className="text-sm text-gray-600 mt-1">
                <div className="flex-1">
                <p className="pb-1 mb-3 border-b-2 border-gray-200"></p>
                  <div className="border-l-2 border-sky-100 pl-4">
                    <ul className="space-y-1.5">
                      <li className="flex items-center text-left">
                        <span className="w-1 h-1 bg-sky-600 rounded-full mr-2 mt-0.5"></span>
                        Primary payroll file
                      </li>
                      <li className="flex items-center text-left">
                        <span className="w-1 h-1 bg-sky-600 rounded-full mr-2 mt-0.5"></span>
                        Secondary payroll file
                      </li>
                      <li className="flex items-center text-left">
                        <span className="w-1 h-1 bg-sky-600 rounded-full mr-2 mt-0.5"></span>
                        Mapping configuration file
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          );
        case 'phase3':
          return (
            <>
              <div className="text-sm text-gray-600 mt-1">
                <div className="flex-1">
                <p className="pb-1 mb-3 border-b-2 border-gray-200"></p>
                  <div className="border-l-2 border-sky-100 pl-4">
                    <ul className="space-y-1.5">
                      <li className="flex items-center text-left">
                        <span className="w-1 h-1 bg-sky-600 rounded-full mr-2 mt-0.5"></span>
                        Consolidated payroll data
                      </li>
                      <li className="flex items-center text-left">
                        <span className="w-1 h-1 bg-sky-600 rounded-full mr-2 mt-0.5"></span>
                        Validation rules sheet
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          );
      }
    };

    return (
      <div className="mt-2 p-3 bg-gray-50 rounded-lg border border-gray-200">
        <div className="flex items-center">
          <div className="w-2 h-2 bg-sky-800 rounded-full mr-2"></div>
          <p className="text-sm font-semibold text-gray-900 flex justify-between items-center w-full">
            {selectedPhase === 'phase1' ? (
              <>
                Generate Variance Log & Metric Charts
                <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10 ml-2">
                  <svg className="mr-1.5 h-3 w-3 text-red-400" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>
                  1 File Required
                </span>
              </>
            ) : selectedPhase === 'phase2' ? (
              <>
                Mapping and Payroll Matching
                <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10 ml-2">
                  <svg className="mr-1.5 h-3 w-3 text-red-400" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>
                  3 Files Required
                </span>
              </>
            ) : (
              <>
                Advanced Reconciliation
                <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10 ml-2">
                  <svg className="mr-1.5 h-3 w-3 text-red-400" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>
                  1 File Required
                </span>
              </>
            )}
          </p>
        </div>

        <div className="mt-1 pl-3">
          <div className="text-sm text-gray-600">
            {getPhaseRequirements()}
          </div>
        </div>

        {files.length > 0 && (
          <div className="mt-2 pt-1 border-t border-gray-200">
            <p className="text-sm font-medium flex items-center"
               style={{ color: hasCorrectFileCount() ? '#047857' : '#DC2626' }}>
              <span className="w-1.5 h-1.5 rounded-full mr-2"
                    style={{ backgroundColor: hasCorrectFileCount() ? '#047857' : '#DC2626' }}></span>
              {files.length} of {required} file{required > 1 ? 's' : ''} selected
            </p>
          </div>
        )}
      </div>
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm']
    },
    useFsAccessApi: false,
    onDrop: useCallback((acceptedFiles: FileWithPath[]) => {
      const maxFiles = getRequiredFileCount(selectedPhase);

      if (acceptedFiles.length > 0) {
        if (files.length + acceptedFiles.length > maxFiles) {
          toast.error(`Maximum ${maxFiles} file${maxFiles > 1 ? 's' : ''} is allowed for this process!!!`, {
            duration: 3000,
            position: 'top-center',
            style: {
              background: '#FEE2E2',
              color: '#991B1B',
              padding: '16px',
              borderRadius: '8px',
              fontSize: '16px',
              fontWeight: '500',
              boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
              border: '1px solid #FCA5A5',
              marginTop: '2rem',
              minWidth: '500px',
              whiteSpace: 'nowrap',
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            },
            iconTheme: {
              primary: '#DC2626',
              secondary: '#FEE2E2'
            }
          });
          return;
        }

        const newFiles = [...files, ...acceptedFiles].slice(0, maxFiles);
        setFiles(newFiles);
        setFilesSelected(true);
        setProcessStatus('');

        if (newFiles.length > maxFiles) {
          setProcessError(`Only ${maxFiles} file${maxFiles > 1 ? 's are' : ' is'} allowed for this phase.`);
          setProcessStatus('Error');
        } else {
          setProcessError('');
        }
      } else {
        setProcessStatus('Error');
        setProcessError('Only .xlsx and .xlsm files are allowed');
      }
    }, [files, selectedPhase]),
  });

  const handleRemove = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    event.preventDefault();
    event.stopPropagation();
    const newFiles = [...files];
    newFiles.splice(index, 1);
    if (newFiles.length === 0) {
      setFilesSelected(false);
    }
    setFiles(newFiles);
  };

  const handleRemoveAll = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setFiles([]);
    setFilesSelected(false);
    setProcessStatus('');
  };

  async function handleRunCheck(event: any) {
    event.preventDefault();
    const arrayFileNames : string[] = [];

    try {
      setProcessStatus('Uploading Files...');

      let index = 1;
      for (const file of files) {
        const encodedName = encodeURIComponent(file.name);

        const checksum = MD5(
          encodedName + file.lastModified + file.size + file.type,
        ).toString();
        const fileName = checksum + '/' + encodedName;
        arrayFileNames.push(fileName);

        setProcessStatus(`Uploading Files (${index}/${files.length})`);

        const fileUploadRes = await uploadS3(
            reconciliatorS3KeyFolder,
            fileName,
            file,
            (file as any).type,
            auth0Token,
        );
        index++;
      };

      setProcessStatus('Process Started');

      const reconciliateResponse = await reconciliate(
        arrayFileNames,
        auth0Token,
        selectedPhase
      );

      const responseJson = await reconciliateResponse.json();

      if (reconciliateResponse.status === 200) {
        const url = await downloadS3(responseJson, auth0Token);
        const parts = responseJson.split('/');
        const filename = parts[parts.length - 1];

        fetch(url).then(response => {
          response.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();
          });
        });

        setProcessStatus('Process Done');
        setFiles([]);
        setFilesSelected(false);
      } else {
        console.log(responseJson);
        setProcessStatus('Error');
        setProcessError(responseJson);
      }
    } catch (err: any) {
      console.error(err);
      setProcessStatus('Error');
      setProcessError(err);
    }
  }

  // run only on once
  useEffect(() => {
    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAuth0Token(token);
    };
    fetchAccessToken().catch(console.error);
  }, [getAccessTokenSilently]);

  return (
    <div className="pt-2">
      <PhaseSelector onPhaseSelect={setSelectedPhase} />
      {(processStatus === '' || processStatus === 'Error' || processStatus === 'Process Done') && (
        <div
          {...getRootProps()}
          className="mt-1 flex flex-col justify-center items-center rounded-lg border-dashed border-2 border-gray-900/25 px-6 py-6 bg-white text-center cursor-pointer block mb-3 mx-auto max-w-8xl shadow-lg focus:outline-none min-h-[400px]"
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              e.currentTarget.blur();
            }
          }}
          style={{
            borderColor: isDragActive ? '#6366F1' : undefined,
            backgroundColor: isDragActive ? '#EEF2FF' : undefined
          }}
        >
          <input {...getInputProps()} />
          {!filesSelected && (
            <div className="text-center">
            <FontAwesomeIcon icon={faCloudArrowUp} size="6x" className="text-gray-400 mb-2" />
              {isDragActive ? (
                <div className="transform transition-all duration-300 animate-bounce">
                  <p className="text-xl font-semibold text-indigo-600 animate-pulse">
                    Release to upload files...
                  </p>
                  <p className="text-sm text-gray-500 mt-2">
                    <span className="animate-ping inline-flex h-2 w-2 rounded-full bg-indigo-400 opacity-75 mr-2"></span>
                    Ready to process
                  </p>
                </div>
              ) : (
                <>
                  <div className="flex justify-center items-center text-lg font-medium text-gray-600 mb-2">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>Upload files</span>
                        </label>
                        <p className="pl-2">or Drag&amp;Drop</p>
                  </div>
                  <div className="text-xs text-gray-500 flex flex-col items-center space-y-2">
                        <div className="flex items-center space-x-2">
                          <DocumentIcon className="h-4 w-4 text-gray-400" />
                          <p>Accepted formats: <span className="font-medium">.xlsm, .xlsx</span></p>
                        </div>
                  </div>
                  {getPhaseFileMessage()}
                </>
              )}
            </div>
          )}
        {filesSelected && (
          <div className="w-full">
            <div className="text-center mb-2">
              {getPhaseFileMessage()}
            </div>
            <ul className="space-y-2">
              {files.map((file, index) => (
                <li key={index} className="rounded-md bg-indigo-200 p-4 py-2 px-4" >
                  <div className="flex">
                    <div className="flex-shrink-0  pt-1">
                      <DocumentIcon className="h-5 w-5 text-black" aria-hidden="true" />
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <p className="text-sm text-black pt-1">{file.name}</p>
                      <button className="inline-flex items-center gap-1.5 rounded-md bg-red-800/90 px-2.5 py-1.5 text-sm font-semibold text-white
                        hover:bg-red-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                        focus-visible:outline-red-800 transition-colors duration-200"
                      onClick={(event) => handleRemove(event, index)}>
                        <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clipRule="evenodd" />
                        </svg>
                        Remove
                      </button>
                    </div>
                  </div>
                </li>
              ))}
              {filesSelected && (
                <div className="pt-2">
                  <button className="inline-flex items-center gap-1.5 rounded-md bg-red-800/90 px-2.5 py-1.5 text-sm font-semibold text-white
                    hover:bg-red-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                    focus-visible:outline-red-800 transition-colors duration-200 float-right mr-1"
                  onClick={(event) => handleRemoveAll(event)}>
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                    </svg>
                    Remove All
                  </button>
                </div>
              )}
            </ul>
          </div>
        )}
      </div>
      )}
      {files.length > 0 && (
        <div className="flex justify-center mx-auto max-w-5xl">
          {processStatus === '' && (
            <button
              type="button"
              onClick={handleRunCheck}
              disabled={!hasCorrectFileCount()}
              className={`${
                hasCorrectFileCount()
                  ? 'bg-sky-800 hover:bg-sky-900 focus-visible:outline-sky-600'
                  : 'bg-gray-400 cursor-not-allowed'
              } rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
            >
              Start the Process
            </button>
          )}
        </div>
      )}
      {processStatus && processStatus !== 'Error' && processStatus !== 'Process Done' && (
        <div className="mt-1 mb-3 mx-auto max-w-8xl">
          <div className="bg-white rounded-lg p-8 text-center min-h-[250px] relative">
            <div className="max-w-sm mx-auto absolute inset-x-0 top-12">
              <div className="flex flex-col items-center">
                <div className="mb-6">
                  <svg
                    className="w-16 h-16 animate-spin text-indigo-600"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                </div>
                <div className="flex flex-col items-center gap-3">
                  <div className="flex flex-col items-center">
                    <p className="text-2xl font-semibold text-indigo-600">
                      {processStatus.split('(')[0]}
                    </p>
                    <p className="text-xl text-indigo-500 font-medium mt-1">
                      {processStatus.includes('(') ? `(${processStatus.split('(')[1]}` : ''}
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <div className="w-2 h-2 bg-indigo-600 rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
                    <div className="w-2 h-2 bg-indigo-600 rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
                    <div className="w-2 h-2 bg-indigo-600 rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {processStatus === 'Error' && (
        <Error
          message={processError}
          onClose={() => {
            setProcessStatus('');
            setProcessError('');
          }}
        />
      )}
      {processStatus === 'Process Done' && (
        <Success
          message={'File Downloaded in Default Download Folder'}
          onClose={() => {
            setProcessStatus('');
            setProcessError('');
          }}
        />
      )}
    </div>
  );
};


export default FileDropzone;
