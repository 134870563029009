import React, { useState } from 'react';

interface PhaseSelectorProps {
  onPhaseSelect: (phase: string) => void;
}

const PhaseSelector: React.FC<PhaseSelectorProps> = ({ onPhaseSelect }) => {
  const [selectedPhase, setSelectedPhase] = useState('phase1');

  const handlePhaseChange = (phase: string) => {
    setSelectedPhase(phase);
    onPhaseSelect(phase);
  };

  return (
    <div className="mb-6">
      <div className="border-b border-gray-200 pb-2 mb-2">
        <div className="flex items-center gap-2">
          <svg className="w-5 h-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M4.25 2A2.25 2.25 0 002 4.25v2.5A2.25 2.25 0 004.25 9h2.5A2.25 2.25 0 009 6.75v-2.5A2.25 2.25 0 006.75 2h-2.5zm0 9A2.25 2.25 0 002 13.25v2.5A2.25 2.25 0 004.25 18h2.5A2.25 2.25 0 009 15.75v-2.5A2.25 2.25 0 006.75 11h-2.5zm9-9A2.25 2.25 0 0011 4.25v2.5A2.25 2.25 0 0013.25 9h2.5A2.25 2.25 0 0018 6.75v-2.5A2.25 2.25 0 0015.75 2h-2.5zm0 9A2.25 2.25 0 0011 13.25v2.5A2.25 2.25 0 0013.25 18h2.5A2.25 2.25 0 0018 15.75v-2.5A2.25 2.25 0 0015.75 11h-2.5z" clipRule="evenodd" />
          </svg>
          <h2 className="text-lg font-semibold text-gray-900">
            Select Process Phase
          </h2>
        </div>
      </div>
      <div className="space-y-2">
        <div
          className="relative flex items-center group transition-all duration-200 hover:bg-gray-50/100 hover:shadow px-2 py-1 -mx-2 rounded-md cursor-not-allowed"
          onClick={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          <div className="absolute -top-2 left-1/2 -translate-x-1/2 -translate-y-full opacity-0 group-hover:opacity-100 transition-all duration-200 pointer-events-none">
            <div className="bg-gray-900 text-white text-xs px-2 py-1 rounded shadow-lg whitespace-nowrap">
              This feature will be available in the future release
              <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 transform rotate-45 w-2 h-2 bg-gray-900"></div>
            </div>
          </div>
          <input
            id="phase2"
            name="phase"
            type="radio"
            checked={selectedPhase === 'phase2'}
            onChange={() => handlePhaseChange('phase2')}
            disabled
            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 transition-all duration-200 cursor-not-allowed"
          />
          <label htmlFor="phase2" className="ml-3 block text-sm font-medium leading-6 text-gray-400 cursor-not-allowed">
            Mapping and Payroll Matching
            <span className="ml-2 inline-flex items-center rounded-md bg-gray-50/50 px-2 py-1 text-xs font-medium text-gray-600/75 ring-1 ring-inset ring-gray-500/10 transition-all duration-200 group-hover:bg-gray-100/50">
              Coming Soon
            </span>
          </label>
        </div>
        <div
          className="relative flex items-center group transition-all duration-200 hover:bg-gray-50/100 hover:shadow px-2 py-1 -mx-2 rounded-md cursor-not-allowed"
          onClick={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          <div className="absolute -top-2 left-1/2 -translate-x-1/2 -translate-y-full opacity-0 group-hover:opacity-100 transition-all duration-200 pointer-events-none">
            <div className="bg-gray-900 text-white text-xs px-2 py-1 rounded shadow-lg whitespace-nowrap">
              This feature will be available in the future release
              <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 transform rotate-45 w-2 h-2 bg-gray-900"></div>
            </div>
          </div>
          <input
            id="phase3"
            name="phase"
            type="radio"
            checked={selectedPhase === 'phase3'}
            onChange={() => handlePhaseChange('phase3')}
            disabled
            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 transition-all duration-200 cursor-not-allowed"
          />
          <label htmlFor="phase3" className="ml-3 block text-sm font-medium leading-6 text-gray-400 cursor-not-allowed">
            Reconciliation Report
            <span className="ml-2 inline-flex items-center rounded-md bg-gray-50/50 px-2 py-1 text-xs font-medium text-gray-600/75 ring-1 ring-inset ring-gray-500/10 transition-all duration-200 group-hover:bg-gray-100/50">
              Coming Soon
            </span>
          </label>
        </div>
        <div
          className="flex items-center group transition-all duration-200 hover:bg-gray-50/100 hover:shadow px-2 py-1 -mx-2 rounded-md cursor-pointer"
          onClick={() => handlePhaseChange('phase1')}
        >
          <input
            id="phase1"
            name="phase"
            type="radio"
            checked={selectedPhase === 'phase1'}
            onChange={() => handlePhaseChange('phase1')}
            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 transition-all duration-200"
          />
          <label htmlFor="phase1" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
            Generate Variance Log &amp; Metric Charts
          </label>
        </div>
      </div>
    </div>
  );
};

export default PhaseSelector;
