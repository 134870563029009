import logo from "../assets/logo.svg";

export default function Footer() {
  return (
    // Footer should be at the bottom of the page
    <footer className="bg-white border-t border-gray-900/10 md:flex md:items-center md:justify-between mt-auto">
      <div className="w-full mx-auto p-4">
        <div className="sm:flex sm:items-center sm:justify-between">
          <a
            href="https://www.safeguard-pay.com"
            className="flex items-center mb-4 sm:mb-0"
          >
            <img
              src={logo}
              className="h-10 mr-3"
              alt="Safeguard-Pay Logo"
            />
          </a>
          <span className="block text-sm text-gray-500 sm:text-center text-gray-400">
          © 2025{' '}
          <a
            href="https://www.safeguard-pay.com/"
            className="hover:underline"
          >
            Safeguard-Pay
          </a>
          {' '}
          | All Rights Reserved.
        </span>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
              <a
                href="https://www.safeguard-pay.com/our-company/"
                className="mr-4 hover:text-[#472247] md:mr-6 "
              >
                About
              </a>
            </li>
            <li>
              <a
                href="https://www.safeguard-pay.com/privacy/"
                className="mr-4 hover:text-[#472247] md:mr-6"
              >
                Privacy
              </a>
            </li>
            <li>
              <a
                href="https://www.safeguard-pay.com/interact/contact-us/"
                className="hover:text-[#472247]"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
