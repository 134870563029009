import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export default function SignOut() {
  const { isAuthenticated, logout } = useAuth0();
  const [isHovered, setIsHovered] = useState(false);

  if (!isAuthenticated) {
    return null;
  }

  const subpage = window.location.pathname.substring(1);
  const logoutUrl = window.location.origin + '/' + subpage;

  return (
    <button
      className="flex items-center gap-2 px-3 py-1.5 rounded-md transition-all duration-200 bg-red-50 border border-red-300 hover:bg-red-100 group"
      onClick={() => logout({ logoutParams: { returnTo: logoutUrl } })}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      title="Sign out"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-5 h-5 text-red-600 group-hover:text-red-700 transition-colors duration-200"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
        />
      </svg>
      {isHovered && (
        <span className="text-sm font-medium text-red-700 animate-fade-in">
          Sign out
        </span>
      )}
    </button>
  );
}
