import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Toaster } from 'react-hot-toast';
import { createPortal } from 'react-dom';

import Sidebar from './Sidebar';
import SignOut from './SignOut';
import Footer from './Footer';
import logo from '../assets/logoWhite.svg';

interface LayoutProps {
  children: React.ReactNode;
  activeSubpage: string;
}

export default function Layout({ children, activeSubpage }: LayoutProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      {createPortal(
        <div className="fixed top-2 right-2 z-[100]">
          <SignOut />
        </div>,
        document.body
      )}

      <div className="flex flex-col min-h-screen">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div
                    className="flex grow flex-col gap-y-5 overflow-y-auto
                    bg-gradient-to-b from-[#432043] to-[#172849] px-6 pb-4"
                  >
                    <div className="flex h-16 shrink-0 items-center">
                      <a
                        href="https://www.safeguard-pay.com"
                        className="flex items-center mb-4 sm:mb-0"
                      >
                        <img
                          src={logo}
                          alt="Safeguard-Pay Logo"
                          className="w-35 h-11 mt-2"
                        />
                      </a>
                    </div>
                    <Sidebar activeSubpage={activeSubpage} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div
            className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200
              bg-gradient-to-b from-[#432043] to-[#172849] px-6 pb-4"
          >
            <div className="flex h-16 shrink-0 items-center">
              <a
                href="https://www.safeguard-pay.com"
                className="flex items-center mb-4 sm:mb-0"
              >
                <img
                  src={logo}
                  alt="Safeguard-Pay Logo"
                  className="w-35 h-11 mt-2"
                />
              </a>
            </div>
            <Sidebar activeSubpage={activeSubpage} />
          </div>
        </div>

        <div className="lg:pl-72 min-w-fit flex flex-col min-h-screen">
          <div className="sticky top-0 z-40 lg:mx-auto w-full">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

            <div className="flex flex-1 gap-x-4 lg:gap-x-6">
              <div className="relative flex flex-1"></div>
            </div>
          </div>

          <main className="py-2 flex-grow mt-8">{children}</main>
          <Footer />
        </div>
      </div>
      <Fragment>
        <Toaster />
      </Fragment>
    </>
  );
}
